import React, { Component } from 'react';
import { graphql, StaticQuery,Link } from "gatsby";
import './css/slider.css'
import GridPostCategories from "../../elements/GridPostCategories/GridPostCategories"

export default (props) => (
    <StaticQuery
      query={graphql`
      query {
            allWpPost {
                nodes {
                title
                content
                date
                slug
                categories {
                    nodes {
                    slug
                    name
                    }
                }
                tags {
                    nodes {
                    slug
                    name
                    }
                }
                featuredImage {
                    node {
                    mediaItemUrl
                    }
                }
                }
            }
          }
      `}
      
      render={data => (  
          
        <div className="more-posts-slider-wrapper">
            <div data-delay="4000" data-animation="slide" data-autoplay="1" data-nav-spacing="5" data-duration="500" className="more-posts-slider w-slider">
                <div className="more-posts-mask w-slider-mask">
                {data.allWpPost.nodes.map(({ node },index) => (
                    new Date(data.allWpPost.nodes[index].date).getMonth()==props.month? 
                    <Link to={"/"+data.allWpPost.nodes[index].slug}>
                        <div className="more-posts-slide w-slide">
                            <div className="more-posts-slide-block">
                                <div className="more-posts-slide-content"  style={{ "background-image":`url(${data.allWpPost.nodes[index].featuredImage? data.allWpPost.nodes[index].featuredImage.node.mediaItemUrl :""})`}} >
                                <div className="opacity-div-grid">
                                    <GridPostCategories tags={data.allWpPost.nodes[index].tags}/>
                                    <h1 className="post-title-slider posts-reco">{data.allWpPost.nodes[index].title}</h1>
                                    <Link to={"/"+data.allWpPost.nodes[index].slug} className="read-more-slider w-inline-block">
                                    <div className="read-more-link-slider post-reco">Read More</div>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </Link>                    
                    : ""
                ))}                 
                </div>
                <div className="left-arrow w-slider-arrow-left">
                    <div className="w-icon-slider-left"></div>
                </div>
                <div className="right-arrow w-slider-arrow-right">
                    <div className="w-icon-slider-right"></div>
                </div>
                <div className="slider-nav w-slider-nav w-slider-nav-invert w-shadow"></div>
            </div>
        </div>
      )}
    />
)

