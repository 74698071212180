import React from 'react';
import LinkedIn from '../../images/linkedin2x.png'
import Twitter from '../../images/twitter2x.png'
import './css/authorBlock.css'
const AuthorBlock = (props) => {
    return (
        <div className="author-block-wrapper">
            <div className="author-image" style={{backgroundImage: "url("+props.author.node.avatar.url+")"}}></div>
            <div className="author-content-block">
                <h1 className="written-by">Written by</h1>
                <div className="author-name-icons">
                    <h1 className="post-author">{props.author.node.name}</h1>
                    <img src={LinkedIn} alt="" className="social-icon"/>
                    <img src={Twitter} alt="" className="social-icon"/>
                </div>
                <div className="about-author">{props.author.node.description}</div>
            </div>
        </div>
    );
};

export default AuthorBlock;