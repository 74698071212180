import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTransition from 'gatsby-plugin-page-transitions';
import Post from "../views/Post/Post"
import logo from "../images/logo.png"

export default function PostPage({ data, pageContext }) {
  const post = data.allWpPost.nodes[0]
  const seoImage = post.featuredImage.node.mediaItemUrl
  const { postDate } = pageContext
  
  const baseUrl = 'https://thesikhmag.com/';
      
      // const category=Object.values(post.categories)[0].name
      const disqusShortname = "thesikhmag-com" //found in your Disqus.com dashboard
      const disqusConfig = {
        url: baseUrl + post.slug, //this.props.pageUrl  baseUrl + post.slug
        identifier: post.id, //this.props.uniqueId
        title:post.title  //this.props.title
      }
  return (
    <PageTransition
    defaultStyle={{
      transition: 'right 450ms cubic-bezier(0.47, 0, 0.75, 0.72)',
      right: '100%',
      position: 'absolute',
      width: '100%',
    }}
    transitionStyles={{
      entering: { right: '0%' },
      entered: { right: '0%' },
      exiting: { right: '100%' },
    }}
    transitionTime={450}
  >
      <Layout>
      <SEO title={post.title}
          image={seoImage}
          url={baseUrl+post.slug}
          description={post.excerpt.replace(/(<([^>]+)>)/gi, "")}
          />
      <Post data={post} postDate={postDate} disqusShortname={disqusShortname} disqusConfig={disqusConfig}></Post>
    </Layout>
    </PageTransition>    
  )
}
export const query = graphql`
  query($slug: String) {
    allWpPost(filter: { slug: { eq: $slug } }) {
        nodes {
          title
          id
          content
          date
          slug
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          author {
            node {
              name
              description
              avatar {
                url
              }
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
  }
`