import React, { Component } from 'react';
import styled from 'styled-components';

import Nav from "../../components/NavigationBlog/NavigationBlog"
import ProgressBar from "../../elements/ProgressBar/ProgressBar"
import FeaturedImage from "../../elements/PostFeaturedImage/PostFeaturedImage"
import {BodyWrapper,PostContentWrapper,PostTitle,AboutUsWrap} from "../../css/style"
import AuthorCategoriesBlock from "../../elements/AuthorCategoriesBlock/AuthorCategoriesBlock"
import PostContentBlock from "../../elements/PostContentBlock/PostContentBlock"
import PostShare from "../../elements/PostShare/PostShare"
import AuthorBlock from '../../elements/AuthorBlock/AuthorBlock'
import PostPageButton from '../../elements/PostPageButton/PostPageButton'
import Divider from "../../elements/Divider/Divider"
import Slider from "../../components/Slider/Slider"
import { DiscussionEmbed } from 'disqus-react';

// const para="As we celebrate 66 years of Independence we must also mourn the separation of two nations. Necessary as it may have been for political reasons, the result caused unquantifiable loss of life, property and peace. Not only did it leave a bitter after taste but it also led us to believe that our neighbouring nation where many of our families once resided is our evil half. The partition is known to be the largest exodus of people ever, at a staggering 10 million. Loss of life is measured close to 1 million. The fact that India is home to an equal if not larger number of Muslims than Pakistan shows us that the amputation of the erstwhile India was only political.All that we know about our Siamese twin of a country today is mostly spoon-fed to us by the"

class Post extends Component {
    constructor (props){
        super(props);
        this.state = {
            scrollPosition: 0,
            comments:false,
        }
        this.toggleComments = this.toggleComments.bind(this);
    }
    
    listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            this.calculateScrollDistance();
          });
        });
      }
    
      calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = this.getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
        this.setState({
          scrollPostion,
        });
      }
    
      getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
      }
      getMonth = () =>{
        const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        var date = new Date(this.props.data.date); 
        return (mlist[date.getMonth()])
      }
      getMonthNumber = () =>{
        // const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        var date = new Date(this.props.data.date); 
        return (date.getMonth())
      }
      toggleComments(){
        this.setState({
          comments:!this.state.comments,
        });
      }
      componentDidMount() {
        this.listenToScrollEvent();
      }
    render() {
      
        return (            
            <div>
                <BodyWrapper>
                   <Nav/>
                   <ProgressBar scroll={this.state.scrollPostion + '%'} active={true} />
                   <PostContentWrapper>
                       {this.props.data.featuredImage?
                            <FeaturedImage postImage={this.props.data.featuredImage.node.mediaItemUrl}/>
                        :""
                        }
                        
                        <PostTitle>{this.props.data.title}</PostTitle>
                        <AuthorCategoriesBlock date={this.props.data.date} tags={this.props.data.tags} author={this.props.data.author}/>
                        <PostContentBlock>
                            <div dangerouslySetInnerHTML={{ __html: this.props.data.content }} style={{"width":"100%"}} /> 
                            <div className="editors-end-line post-content-end" ></div> 
                            <PostShare slug={this.props.data.slug} title={this.props.data.title}/>                                                  
                        </PostContentBlock>  
                        {/* <AuthorCategoriesBlock date={this.props.data.date} tags={this.props.data.tags} reverse={true}/> */}
                       
                        <AuthorBlock author={this.props.data.author}/>    
                        <PostPageButton content={this.state.comments? "Hide Comments":"View Comments"} function={this.toggleComments}/> 
                        <div className={this.state.comments? "comments-section active":"comments-section"}>
                            <div className="discus-plugin">
                                <DiscussionEmbed shortname={this.props.disqusShortname} config={this.props.disqusConfig} />
                            </div>
                        </div> 
                        <Divider CategoryHeading={"More from "+this.getMonth()} className="v-85"/> 

                        <Slider date={this.props.postDate} month={this.getMonthNumber()} /> 

                   </PostContentWrapper>
                </BodyWrapper>
            </div>
        );
    }
}

export default Post;