import React from 'react';
import LinkedIn from '../../images/linkedin2x.png'
import Facebook from '../../images/facebook2x.png'
import Twitter from '../../images/twitter2x.png'
import Email from '../../images/email2x.png'
import Whatsapp from '../../images/Whatsapp.png'

import './css/postShare.css'
const PostShare = (props) => {
    const baseUrl = 'https://thesikhmag.com';
    const slug=props.slug
    const title=props.title
    return (
        <div className="share-post">
          <div className="share">Share :</div>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}/${slug}`} target="_blank">
              <img src={Facebook} alt="" className="social-icon"/>
          </a>
          <a href={'https://www.linkedin.com/shareArticle?mini=true&url='+baseUrl+'/'+slug+'&title='+title+'&summary=&source='} target="_blank">
              <img src={LinkedIn} alt="" className="social-icon"/>
          </a>
         
          <a href={'https://twitter.com/intent/tweet?url='+baseUrl+'/'+slug+'&text='+title} target="_blank">
              <img src={Twitter} alt="" className="social-icon"/> 
          </a>
          {/* www.thesikhmag.com%2Fsikhyas-monthly-mindfulness
          <a href={'https://api.whatsapp.com/send?text=https%3A%2F%2F'+baseUrl+'%/'+slug} target="_blank">
              <img src={Whatsapp} alt="" className="social-icon"/>
          </a> */}
          <a href={'https://api.whatsapp.com/send?text=https%3A%2F%2Fthesikhmag.com%2F'+slug} target="_blank">
              <img src={Whatsapp} alt="" className="social-icon"/>
          </a>
          <a href={'mailto:info@example.com?&subject=&body='+baseUrl+'/'+slug} target="_blank">
              <img src={Email} alt="" className="social-icon"/>
          </a>
        </div>
    );
};

export default PostShare;