import React, { Component } from 'react';
import {Link} from "gatsby";
import "./css/authorCategoriesBlock.css"

class AuthorCategoriesBlock extends Component {
    constructor(props)
    {
        super(props);
    }
    nth = function(date) {
        var d=date.getDate();
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }
      getMonthName = function(month){
        const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        return mlist[month];
      }
      getDateFormat = function(date){
        var date = new Date(date); 
        var suffix=this.nth(date)
        return (date.getDate()+suffix+' '+this.getMonthName(date.getMonth())+' '+date.getFullYear())
      }
    render() {
        return (          
            <div className={this.props.reverse? "author-categories-block reverse" :"author-categories-block"}>
                <div className={this.props.reverse? "author-content reverse":"author-content"}>
                    <div className="author-name">By <strong><em>{this.props.author.node.name}</em></strong></div>
                    <div className="post-date">{this.getDateFormat(this.props.date)}</div>
                </div>
                <div className={this.props.reverse?"post-categories-wrapper reverse":"post-categories-wrapper"}>
                    {this.props.tags.nodes.map((category, i) => (
                      <Link to={'/category/'+category.slug} className="post-category grey-bg text-deco-none" key={i}>{category.name}</Link>                 
                    ))}
                </div>
          </div>          
        );
    }
}

export default AuthorCategoriesBlock;