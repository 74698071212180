import React from 'react';
import './css/postContentBlock.css'

function PostContentBlock(props) {
    return (
        <div className="post-content-block" style={ props.padding=="null" ? { paddingBottom:'1%', paddingTop: '1%'} : {}}>
            {props.children}
        </div>
    );
}

export default PostContentBlock;